import Flickity from 'flickity'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

window.addEventListener('load', function () {
  if (document.getElementById('monthlySlider-1')) {
    const recommendSlider = new Flickity('#monthlySlider-1', {
      autoPlay: true,
      wrapAround: true,
      contain: true,
      prevNextButtons: false
    })
    $('.recommend--arw-r').on('click', function (e) {
      e.preventDefault()
      recommendSlider.next()
    })
    $('.recommend--arw-l').on('click', function (e) {
      e.preventDefault()
      recommendSlider.previous()
    })
  }
  $('.gl_footer--top_btn').on('click', function(e) {
    e.preventDefault()
    gsap.to(window, { duration: 0.8, ease: 'power2.inOut', scrollTo: 0 })
  })

  // 次の要素にopenを付与する。
  // subNavのボタンを押した場合は、他のsubNavを非表示にする
  // mainNavのボタンを押した場合は、subNavとmainNavどちらも非表示にする
  // [使い方]
  // js-mainNavButtonクラスを付与した次の要素にopenクラスを付与したい場合に使ってください。
  // 次の要素にはjs-mainNavもしくはjs-subNavのどちらかのクラスをつけてください（必須）。
  // js-mainNavは第一階層、js-subNavは第二階層にクラスを付与してください。
  $('.js-mainNavButton').on('click', function (e) {
    e.preventDefault()

    // 次の要素が表示されていなかったら他の要素を非表示にする
    if ($(this).next().hasClass('open') === false) {
      if ($(this).next().hasClass('js-mainNav')) {
        $('.js-mainNav').removeClass('open')
        $('.js-subNav').removeClass('open')
        $('.js-mainNavButton').removeClass('active')
      }
      if ($(this).next().hasClass('js-subNav')) {
        $('.js-subNav').removeClass('open')
      }
    }
    if ($(this).next().hasClass('open')) {
      $(this).next().removeClass('open')
    } else {
      $(this).next().addClass('open')
    }

    // 自分以外の兄弟要素のactiveを削除する
    $(this).siblings('.active').removeClass('active');

    // クリックされた要素にactiveをつける、ついてる場合は削除する
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
    } else {
      $(this).addClass('active')
    }

  })

  if(isSp() === false) {
    const bannerSlider = new Flickity('#bannerSlider', {
      initialIndex: 1,
      prevNextButtons: false,
      autoPlay: true,
      wrapAround: true,
      contain: true,
      //draggable: false
    })
  }
  if (isSp() === true) {
    const bannerSlider = new Flickity('#bannerSlider', {
      prevNextButtons: false,
      autoPlay: true,
      wrapAround: true,
      contain: true,
    })
  }
  if (isSp() === true) {
    const bannerSliderSP = new Flickity('#bannerSliderSP', {
      prevNextButtons: false,
      autoPlay: true,
      wrapAround: true,
      contain: true,
    })
  }

  $(function () {
    //header PC 詳しく検索
    $('#js-morebtn').on('click', function() {
      $('.gl_search_detail').toggleClass('active');
    });
    //header PC 詳しく検索ボタンの＋－
    $('#js-morebtn').on('click', function() {
      $('.cmn_header_search--link_btn_cross').toggleClass('active');
    });
    //header PC 詳しく検索背景
    $('#js-morebtn').on('click', function() {
      $('.gl_search_detail--bg').toggleClass('active');
      if($('.gl_search_detail--bg').hasClass('active')) {
        $('.gl_search_detail--bg').css('height',`${$('.wrap').height()}px`);
      } else {
        $('.gl_search_detail--bg').css('height',`auto`);
      }
    });
    //gnav PC closeボタン
    $('.js-gnav-close').on('click', function() {
      $(this).parent().removeClass('open');
    });
    //gnav SP メニュー
    $('.js-spmenu').on('click', function() {
      $('.gnav_sp_contents-menu').toggleClass('open');
    });
    //gnav SP 検索
    $('.js-spsearch').on('click', function() {
      $('.gnav_sp_contents-search').toggleClass('open');
    });
    //gnav SP 詳細検索
    $('.js-detail-open').on('click', function() {
      $(this).toggleClass('active');
      $(this).next().toggleClass('open');
    });
    //gnav laptop タブ
    $('.js-tab').on('click', function() {
      $(this).toggleClass('slide');
      $('.gnav--bg').toggleClass('slide');
    });
    $('.gnav--link').on('click', function() {
      $('.js-tab').addClass('hidden');
      const isJsMainNavOpen = $('.js-mainNav').hasClass('open');
      if (isJsMainNavOpen === true) {
        $('.js-tab').addClass('hidden');
      }
      if (isJsMainNavOpen === false) {
        $('.js-tab').removeClass('hidden');
      }
    });
    $('.js-gnav-close').on('click', function() {
      $('.js-tab').removeClass('hidden');
      $('.js-mainNavButton').removeClass('active');
      $('.js-mainNav,.js-subNav').removeClass('open');
    });
    //ranking SP タブ
    $('.js-rankingtab-total').on('click', function() {
      $(this).addClass('active');
      $('.js-rankingtab-newstock').removeClass('active');
      $('.ranking--list-total').addClass('current');
      $('.ranking--list-newstock').removeClass('current');
    });
    $('.js-rankingtab-newstock').on('click', function() {
      $(this).addClass('active');
      $('.js-rankingtab-total').removeClass('active');
      $('.ranking--list-newstock').addClass('current');
      $('.ranking--list-total').removeClass('current');
    });
    
    //スムースリンク
    $('a[href^="#"]').click(function(){
      const speed = 500;
      const href= $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
    
  });
  

  // ヘッダーレシピ数
  function showResultNum() {
    var numArea = $('#resultNum');
    var resultNum = Number(numArea.text());
    var countSpeed = 0.5;
    var num = Math.floor(resultNum / 1.15);

    setTimeout(function countUp() {
      num++;
      if (num > resultNum / 1.03) {
        countSpeed += 4;
      }
      numArea.text(num);
      if (num < resultNum) {
        setTimeout(countUp, countSpeed);
      }
    }, countSpeed);

    numArea.css({'opacity': 1});

  };
  showResultNum();


})

// SP時ヘッダーレシピ数　検索窓をクリック時にz-index解除
$(function () {
  if (isSp() === true) {
    $('.searchbox--search_tag').focus(function() {
      $('.cmn_header--counter').fadeOut();
    }).blur(function() {
      $('.cmn_header--counter').fadeIn();
    });
  }
})

function isSp() {
  if(window.innerWidth > 425) return false
  if(window.innerWidth <= 425) return true
}